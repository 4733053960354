import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  LeoAnalysisType,
  LeoButtonPopoverType,
  LeoConfirmLeavePromptAction,
  LeoDashboardAnalysisScope,
  LeoDashboardAnalysisType,
  LeoStatus,
} from '@vision/ui/enums';
import { Dashboard, Flow, LeoAnalysis, LeoAnalysisProgress, LeoAnalysisSuggestion } from '@vision/ui/interfaces';
import { DashboardsService, FlowsService, LeoService } from '@vision/ui/services';

export interface LeoStateType {
  analysis: LeoAnalysis;
  analyzeHistory: LeoAnalysis[];
  analysisType: LeoAnalysisType;
  buttonPopover: LeoButtonPopoverType;
  confirmLeavePrompt: {
    action: LeoConfirmLeavePromptAction;
    onConfirm: VoidFunction;
    onCancel: VoidFunction;
  };
  credits: number;
  currentAnalyzeProgress: LeoAnalysisProgress;
  dashboards: Dashboard[];
  leoStatus: LeoStatus;
  flows: Flow[];
  historyType: LeoAnalysisType;
  isHistoryVisible: boolean;
  historyLoading: boolean;
}

const initialState: LeoStateType = {
  analysis: null,
  analyzeHistory: [],
  analysisType: null,
  buttonPopover: LeoButtonPopoverType.NONE,
  confirmLeavePrompt: null,
  credits: null,
  currentAnalyzeProgress: null,
  dashboards: [],
  leoStatus: LeoStatus.CLOSED,
  flows: [],
  historyType: null,
  isHistoryVisible: true,
  historyLoading: false,
};

export const LeoState = createSlice({
  name: 'LeoState',
  initialState,
  reducers: {
    setCurrentAnalyzeProgress: (
      state,
      action: PayloadAction<{
        entityId?: string;
        entityName?: string;
      }>,
    ) => {
      if (action.payload) {
        state.analysis = null;
        state.currentAnalyzeProgress = {
          ...state.currentAnalyzeProgress,
          ...action.payload,
          answers: {},
        };
      } else {
        state.currentAnalyzeProgress = null;
      }
    },
    setAnswers: (state, action: PayloadAction<Record<string, string>>) => {
      if (state.currentAnalyzeProgress) {
        state.currentAnalyzeProgress.answers = { ...state.currentAnalyzeProgress.answers, ...action.payload };
      }
    },
    clearAnswers: (state) => {
      if (state.currentAnalyzeProgress) {
        state.currentAnalyzeProgress.answers = {};
      }
    },
    setAnalysisFromHistory: (state, action: PayloadAction<LeoAnalysis>) => {
      state.analysis = { ...action.payload, is_new: false, from_history: true };
      state.currentAnalyzeProgress = null;
      state.confirmLeavePrompt = null;
    },
    toggleHistoryVisibility: (state) => {
      state.isHistoryVisible = !state.isHistoryVisible;
    },
    setPopover: (state, action: PayloadAction<LeoButtonPopoverType>) => {
      state.buttonPopover = action.payload;
    },
    openLeoDrawer: (state) => {
      state.leoStatus = LeoStatus.OPENED;
      state.buttonPopover = LeoButtonPopoverType.NONE;

      if (!state.currentAnalyzeProgress) {
        state.isHistoryVisible = true;
      }
    },
    minimizeLeoDrawer: (state) => {
      state.leoStatus = LeoStatus.MINIMIZED;
      state.buttonPopover = LeoButtonPopoverType.MINIMIZED;
    },
    closeLeoDrawer: (state) => {
      state.analysis = null;
      state.analysisType = null;
      state.buttonPopover = LeoButtonPopoverType.NONE;
      state.confirmLeavePrompt = null;
      state.currentAnalyzeProgress = null;
      state.leoStatus = LeoStatus.CLOSED;
      state.isHistoryVisible = false;
    },
    openLeoSuggestions: (state, action: PayloadAction<string>) => {
      const analysisId = action.payload;
      const analysis = analysisId ? state.analyzeHistory.find((a) => a.id === analysisId) : null;

      if (analysis) {
        state.analysis = analysis;
      }

      state.leoStatus = LeoStatus.SUGGESTIONS;
    },
    setAnalysis: (state, action: PayloadAction<LeoAnalysis>) => {
      state.analysis = action.payload;
    },
    setAnalysisType: (state, action: PayloadAction<LeoAnalysisType>) => {
      state.analysisType = action.payload;
    },
    setConfirmLeavePrompt: (
      state,
      action: PayloadAction<{
        action: LeoConfirmLeavePromptAction;
        onCancel: VoidFunction;
        onConfirm: VoidFunction;
      }>,
    ) => {
      state.confirmLeavePrompt = action.payload;
    },
    resetConfirmLeavePrompt: (state) => {
      state.confirmLeavePrompt = null;
    },
    setHistoryType: (state, action: PayloadAction<LeoAnalysisType>) => {
      state.historyType = action.payload;
    },
    setHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.historyLoading = action.payload;
    },
    setDashboardAnalysisScope: (state, action: PayloadAction<LeoDashboardAnalysisScope>) => {
      if (state.currentAnalyzeProgress) {
        state.currentAnalyzeProgress.dashboardAnalysisScope = action.payload;
      }
    },
    setDashboardAnalysisType: (state, action: PayloadAction<LeoDashboardAnalysisType>) => {
      if (state.currentAnalyzeProgress) {
        state.currentAnalyzeProgress.dashboardAnalysisType = action.payload;
      }
    },
    setDashboardWidgets: (state, action: PayloadAction<string[]>) => {
      if (state.currentAnalyzeProgress) {
        state.currentAnalyzeProgress.dashboardWidgets = action.payload;
      }
    },
    setAnalysisSuggestions: (state, action: PayloadAction<LeoAnalysisSuggestion[]>) => {
      if (state.analysis) {
        state.analysis.comments = action.payload;
      }
    },
    setAnalysisIsNewFlag: (state, action: PayloadAction<boolean>) => {
      if (state.analysis) {
        state.analysis.is_new = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(LeoService.endpoints.analyzeFlow.matchFulfilled, (state, action) => {
      const analysis: LeoAnalysis = {
        ...action.payload.analysis,
        entity_id: state.currentAnalyzeProgress.entityId,
        entity_name: state.currentAnalyzeProgress.entityName,
        entity_type: state.analysisType,
        is_new: true,
      };

      state.analysis = analysis;
      state.currentAnalyzeProgress = null;

      if (state.historyType === state.analysisType) {
        state.analyzeHistory.push(analysis);
      }
    });

    builder.addMatcher(LeoService.endpoints.dashboardAnalyzeHistory.matchPending, (state) => {
      state.analyzeHistory = [];
      state.historyLoading = true;
    });

    builder.addMatcher(LeoService.endpoints.flowAnalyzeHistory.matchPending, (state) => {
      state.analyzeHistory = [];
      state.historyLoading = true;
    });
    builder.addMatcher(LeoService.endpoints.analyzeDashboard.matchFulfilled, (state, action) => {
      const analysis: LeoAnalysis = {
        ...action.payload.analysis,
        entity_id: state.currentAnalyzeProgress.entityId,
        entity_name: state.currentAnalyzeProgress.entityName,
        entity_type: state.analysisType,
        is_new: true,
      };

      state.analysis = analysis;
      state.currentAnalyzeProgress = null;

      if (state.historyType === state.analysisType) {
        state.analyzeHistory.push(analysis);
      }
    });
    builder.addMatcher(LeoService.endpoints.quota.matchFulfilled, (state, action) => {
      state.credits = action.payload.quota;
    });
    builder.addMatcher(LeoService.endpoints.dashboardAnalyzeHistory.matchFulfilled, (state, action) => {
      state.analyzeHistory = action.payload
        .map((dashboardHistory) =>
          dashboardHistory.leo_histories.map((history) => ({
            ...history,
            entity_id: dashboardHistory.id,
            entity_name: dashboardHistory.name,
            entity_type: LeoAnalysisType.DASHBOARD,
          })),
        )
        .flat();

      state.historyLoading = false;
    });
    builder.addMatcher(LeoService.endpoints.flowAnalyzeHistory.matchFulfilled, (state, action) => {
      state.analyzeHistory = action.payload
        .map((flowHistory) =>
          flowHistory.leo_histories.map((history) => ({
            ...history,
            entity_id: flowHistory.id,
            entity_name: flowHistory.name,
            entity_type: LeoAnalysisType.FLOW,
          })),
        )
        .flat();

      state.historyLoading = false;
    });
    builder.addMatcher(LeoService.endpoints.favorite.matchFulfilled, (state, action) => {
      if (state.analysis && state.analysis.id === action.payload.id) {
        state.analysis.is_favourite = true;
      }

      if (state.analyzeHistory.length > 0) {
        const analysis = state.analyzeHistory.find((analysis: LeoAnalysis) => analysis.id === action.payload.id);
        analysis.is_favourite = true;
      }
    });
    builder.addMatcher(LeoService.endpoints.unfavorite.matchFulfilled, (state, action) => {
      if (state.analysis && state.analysis.id === action.payload.id) {
        state.analysis.is_favourite = false;
      }

      if (state.analyzeHistory.length > 0) {
        const analysis = state.analyzeHistory.find((analysis: LeoAnalysis) => analysis.id === action.payload.id);
        analysis.is_favourite = false;
      }
    });
    builder.addMatcher(LeoService.endpoints.setSuggestionReaction.matchFulfilled, (state, action) => {
      const { suggestionId } = action.meta.arg.originalArgs;

      const suggestion = state.analysis.comments.find((comment: LeoAnalysisSuggestion) => comment.id === suggestionId);

      if (suggestion) {
        suggestion.reactions = [...suggestion.reactions, action.payload];
      }

      if (state.analyzeHistory.length > 0) {
        state.analyzeHistory = state.analyzeHistory.filter(
          (historyAnalysis: LeoAnalysis) => historyAnalysis.id !== state.analysis.id,
        );

        state.analyzeHistory.push(state.analysis);
      }
    });
    builder.addMatcher(LeoService.endpoints.destroySuggestionReaction.matchFulfilled, (state, action) => {
      const { reactionId } = action.meta.arg.originalArgs;

      const suggestion = state.analysis.comments.find((comment: LeoAnalysisSuggestion) =>
        comment.reactions.find((r) => r.id === reactionId),
      );

      if (suggestion) {
        suggestion.reactions = suggestion.reactions.filter((r) => r.id !== reactionId);
      }

      if (state.analyzeHistory.length > 0) {
        state.analyzeHistory = state.analyzeHistory.filter(
          (historyAnalysis: LeoAnalysis) => historyAnalysis.id !== state.analysis.id,
        );

        state.analyzeHistory.push(state.analysis);
      }
    });
    builder.addMatcher(DashboardsService.endpoints.dashboards.matchFulfilled, (state, action) => {
      state.dashboards = action.payload;
    });
    builder.addMatcher(FlowsService.endpoints.getFlows.matchFulfilled, (state, action) => {
      state.flows = action.payload;
    });
  },
});

export const {
  clearAnswers,
  closeLeoDrawer,
  minimizeLeoDrawer,
  openLeoDrawer,
  openLeoSuggestions,
  resetConfirmLeavePrompt,
  setAnalysisFromHistory,
  setAnalysisIsNewFlag,
  setAnalysisSuggestions,
  setAnalysisType,
  setAnswers,
  setConfirmLeavePrompt,
  setCurrentAnalyzeProgress,
  setDashboardAnalysisScope,
  setDashboardAnalysisType,
  setDashboardWidgets,
  setHistoryLoading,
  setHistoryType,
  setPopover,
  toggleHistoryVisibility,
} = LeoState.actions;
