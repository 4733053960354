import { getClojureUrl } from '@vision/ui/utils';

const REQUEST_TIMESTAMP_KEY = 'leoCreditRequestTimestamp';
const REQUEST_CREDIT_AMOUNT = 'leoCreditRequestAmount';
const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const leoIframe: HTMLIFrameElement = window.parent.document.querySelector('iframe#leoIframe');

export function navigateParent(url: string) {
  // Do not use window.parent.loation.href here
  // This is triggering internal routing mechanism in the parent clojure frame
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';

  window.parent.document.body.appendChild(link);
  link.click();
  window.parent.document.body.removeChild(link);
}

export function isLeoOnClojure(): boolean {
  return window.parent.location.href.startsWith(getClojureUrl());
}

export function showLeoIframe() {
  if (leoIframe) {
    leoIframe.style.display = 'block';
  }
}

export function setAnalysisIdForLeoSuggestions(analysisId: string) {
  localStorage.setItem('analysisIdForLeoSuggestions', analysisId);
}

export function getAnalysisIdForLeoSuggestions() {
  return localStorage.getItem('analysisIdForLeoSuggestions');
}

export function checkLeoCreditRequestStatus() {
  const lastRequestTimestamp = localStorage.getItem(REQUEST_TIMESTAMP_KEY);
  const lastRequestAmount = localStorage.getItem(REQUEST_CREDIT_AMOUNT);

  if (lastRequestTimestamp) {
    const timestamp = Number(localStorage.getItem(REQUEST_TIMESTAMP_KEY));
    const now = Date.now();

    if (now - timestamp > ONE_DAY_MS) {
      clearLeoCreditRequestStatus();

      return { isPending: false };
    }

    return {
      isPending: true,
      lastAmount: Number(lastRequestAmount),
    };
  }

  return { isPending: false };
}

export function updateLeoCreditRequestStatus(credits: number) {
  localStorage.setItem(REQUEST_TIMESTAMP_KEY, Date.now().toString());
  localStorage.setItem(REQUEST_CREDIT_AMOUNT, credits.toString());
}

export function clearLeoCreditRequestStatus() {
  localStorage.removeItem(REQUEST_TIMESTAMP_KEY);
  localStorage.removeItem(REQUEST_CREDIT_AMOUNT);
}

let pointerEventsQueue: { enabled: boolean; timestamp: number }[] = [];
let processingPromise: Promise<void> | null = null;

async function processPointerEventsQueue() {
  const queueToProcess = [...pointerEventsQueue];
  pointerEventsQueue = [];

  const mostRecent = queueToProcess.reduce((prev, current) => (current.timestamp > prev.timestamp ? current : prev));

  if (leoIframe) {
    leoIframe.style.pointerEvents = mostRecent.enabled ? 'auto' : 'none';
  }

  await new Promise(requestAnimationFrame);
}

export function setLeoIframePointerEvents(enabled: boolean) {
  pointerEventsQueue.push({ enabled, timestamp: Date.now() });

  if (!processingPromise) {
    processingPromise = (async () => {
      while (pointerEventsQueue.length > 0) {
        await processPointerEventsQueue();
      }
      processingPromise = null;
    })();
  }
}

// All this pointer events mechanism will be deleted after Leo only being rendered on vision.
export function createClojurePlaceholderArea(element: HTMLElement, identifier: string) {
  if (!leoIframe) return;

  const existingPlaceholder = leoIframe.parentElement.querySelector(`[data-identifier="${identifier}"]`);

  if (existingPlaceholder) {
    existingPlaceholder.remove();
  }

  const referenceRect = element.getBoundingClientRect();

  const placeholder = document.createElement('div');
  placeholder.dataset.identifier = identifier;
  placeholder.style.position = 'absolute';
  placeholder.style.top = `${referenceRect.top}px`;
  placeholder.style.left = `${referenceRect.left}px`;
  placeholder.style.width = `${referenceRect.width}px`;
  placeholder.style.height = `${referenceRect.height}px`;
  placeholder.style.zIndex = '1000';

  placeholder.addEventListener('mouseover', () => setLeoIframePointerEvents(true));

  if (!element.dataset.hasMouseOutListener) {
    element.dataset.hasMouseOutListener = 'true';
    element.addEventListener('mouseleave', () => setLeoIframePointerEvents(false));
  }

  leoIframe.parentElement.appendChild(placeholder);
}

export function removeClojurePlaceholderArea(identifier: string) {
  if (!leoIframe) return;

  const existingPlaceholder = leoIframe.parentElement.querySelector(`[data-identifier="${identifier}"]`);

  if (existingPlaceholder) {
    existingPlaceholder.remove();
  }
}
