import { Permission, PermissionItem } from '@vision/ui/interfaces';
import { getDeep } from './object.utils';

export function checkPermission(permissionKey: string, permissions: Permission) {
  if (!permissionKey) {
    return false;
  }

  const items = permissionKey.split('.');
  const parent = items[0];

  if (!permissions || !permissions[parent] || !permissions[parent].active) {
    return false;
  }

  const path: string = items.reduce(
    (acc, key, index) => acc + `${key}${index + 1 !== items.length ? '.children.' : ''}`,
    '',
  );

  const item = getDeep<Permission, PermissionItem>(permissions, path);

  return !!item && item.active;
}

export class DesktopNotificationHandler {
  static get isSupported() {
    return 'permissions' in navigator;
  }

  static get state() {
    return Notification.permission;
  }

  static listen(callback: (status: PermissionStatus) => void) {
    navigator.permissions
      .query({
        name: 'notifications',
      })
      .then((notificationPerm) => {
        notificationPerm.onchange = () => {
          callback(notificationPerm);
        };
      });
  }

  static requestPermission() {
    return Notification.requestPermission();
  }
}
