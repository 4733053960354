import { Location, To } from 'react-router-dom';

export function getClojureUrl(path?: string): string {
  return `${window.location.origin}/beta${path ? `/${path}` : ''}`;
}

export function redirectToClojure(path: string, replace = false) {
  const redirectPath = `${getClojureUrl()}${path}`;
  if (replace) {
    window.location.replace(redirectPath);
  } else {
    window.location.href = redirectPath;
  }
}

export function getHrefWithSearchParams(href: string, location: Location): To {
  if (location.pathname === href) {
    return {
      pathname: href,
      search: location.search,
    };
  }

  return href;
}
