import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, ReactNode, Ref, ForwardRefExoticComponent } from 'react';
import { Group, Popover, PopoverProps, ScrollArea, Text, UnstyledButton } from '@mantine/core';
import { If } from '@vision/ui/components';
import classes from './LeoPopover.module.scss';

interface LeoPopoverProps extends Omit<PopoverProps, 'children'> {
  children: ReactNode;
  id?: string;
  width?: number;
}

interface LeoPopoverTargetProps {
  children: ReactNode;
}

interface LeoPopoverDropdownProps {
  actionButtons?: ReactNode;
  content?: string | ReactNode;
  title?: string | ReactNode;
  ref?: Ref<HTMLDivElement>;
  onCloseButtonClick?: () => void;
}

interface LeoPopoverComponent extends ForwardRefExoticComponent<LeoPopoverProps> {
  Dropdown: typeof LeoPopoverDropdown;
  Target: typeof LeoPopoverTarget;
}

const LeoPopover = forwardRef<HTMLDivElement, LeoPopoverProps>(
  (
    {
      children,
      arrowOffset = 100,
      arrowSize = 12,
      classNames = { dropdown: classes.dropdown, arrow: classes.arrow },
      offset = { mainAxis: 30, crossAxis: -75 },
      withArrow = true,
      ...props
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div className={classes.leoPopover} ref={ref}>
        <Popover
          arrowOffset={arrowOffset}
          arrowSize={arrowSize}
          classNames={classNames}
          offset={offset}
          withArrow={withArrow}
          {...props}
        >
          {children}
        </Popover>
      </div>
    );
  },
) as LeoPopoverComponent;

function LeoPopoverTarget({ children }: LeoPopoverTargetProps) {
  return <Popover.Target>{children}</Popover.Target>;
}

const LeoPopoverDropdown = forwardRef<HTMLDivElement, LeoPopoverDropdownProps>(
  ({ title, content, actionButtons, onCloseButtonClick }, ref) => {
    return (
      <Popover.Dropdown className={classes.dropdown} ref={ref}>
        <Group className={classes.header}>
          <If value={!!title}>
            <Text className={classes.title}>{title}</Text>
          </If>
          <If value={!!onCloseButtonClick}>
            <UnstyledButton className={classes.closeButton} onClick={onCloseButtonClick} aria-label="Close">
              <FontAwesomeIcon icon={faTimes} />
            </UnstyledButton>
          </If>
        </Group>

        <If value={!!content}>
          <ScrollArea className={classes.content} type="auto" scrollbarSize={3}>
            {content}
          </ScrollArea>
        </If>

        <If value={!!actionButtons}>
          <Group className={classes.footer}>{actionButtons}</Group>
        </If>
      </Popover.Dropdown>
    );
  },
);

LeoPopover.Target = LeoPopoverTarget;
LeoPopover.Dropdown = LeoPopoverDropdown;

export { LeoPopover };
