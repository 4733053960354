import { If, VLazyLoadMultiSelect, VSelectOptionWithDescription } from '@vision/ui/components';
import { useEmojiContext, useSelectedAccountId } from '@vision/ui/hooks';
import {
  QuestionStyles,
  ReportsDetailGraphicSchemaValues,
  SimpleFlowQuestion,
  SimpleFlowQuestionOption,
} from '@vision/ui/interfaces';
import {
  useLazyFetchFlowQuestionOptionsWithPaginationQuery,
  useLazyFetchFlowQuestionsWithPaginationQuery,
  useLazyFetchFlowsWithPaginationQuery,
} from '@vision/ui/services';
import { getBodyText, insertIfObject, translateErrorMessage } from '@vision/ui/utils';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportsDetailGraphicCompareLayout } from '../ReportsDetailGraphicCompareLayout';
import { ReportsDetailGraphicBaseProps } from '../report-detail-graphic-compare.util';

const QUESTION_WITH_OPTION_TYPES: QuestionStyles[] = [
  'plain',
  'radio',
  'score',
  'checklist',
  'matrix',
  'star',
  'dropdown',
  'hidden_validation',
  'gender',
  'ranking',
  'random',
];

interface ReportsDetailGraphicCompareQuestionOptionProps extends ReportsDetailGraphicBaseProps {}

export function ReportsDetailGraphicCompareQuestionOption({
  compareItemType,
  required,
}: ReportsDetailGraphicCompareQuestionOptionProps) {
  const { t, i18n } = useTranslation();
  const { textWithNativeEmoji } = useEmojiContext();
  const accountId = useSelectedAccountId();
  const formik = useFormikContext<ReportsDetailGraphicSchemaValues>();
  const isFlowSelected = formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds.length > 0;
  const isFlowQuestionSelected = formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds.length > 0;

  const handleDefaultQuestionValuesLoaded = (data: SimpleFlowQuestion[]) => {
    // data içindeki idleri formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds içinde varsa onu sil
    const newIds = formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds.filter((id) =>
      data.some((item) => item.id === id),
    );

    formik.setFieldValue('selectedCompareCriteria.selectedQuestionOptionQuestionIds', newIds);
  };

  const handleOnChangeFlow = (values: string[]) => {
    if (values.length === 0) {
      formik.setValues({
        ...formik.values,
        selectedCompareCriteria: {
          ...formik.values.selectedCompareCriteria,
          selectedQuestionOptionQuestionIds: [],
          selectedQuestionOptionIds: [],
        },
      });
    }
    formik.setFieldValue('selectedCompareCriteria.selectedQuestionOptionFlowIds', values);
  };

  const handleDefaultQuestionOptionValuesLoaded = (data: SimpleFlowQuestionOption[]) => {
    // data içindeki idleri formik.values.selectedCompareCriteria.selectedQuestionOptionIds içinde varsa onu sil
    const newIds = formik.values.selectedCompareCriteria.selectedQuestionOptionIds.filter((id) =>
      data.some((item) => item.id === id),
    );
    formik.setFieldValue('selectedCompareCriteria.selectedQuestionOptionIds', newIds);
  };

  const mapItemToSelectOption = (item: SimpleFlowQuestionOption) => ({
    value: item.id,
    label: textWithNativeEmoji(getBodyText(item.body, i18n.language)),
    ...insertIfObject(!!item?.question_body, {
      description: textWithNativeEmoji(getBodyText(item.question_body, i18n.language)),
    }),
    ...insertIfObject(!!item?.flow_name, {
      title: `${textWithNativeEmoji(getBodyText(item.question_body, i18n.language))}\n${
        item?.flow_name && `${t('flowName')}: ${item?.flow_name}`
      }`,
    }),
  });

  return (
    <ReportsDetailGraphicCompareLayout title={t(`page-reports:reportCompareGroupItemType.${compareItemType}`)}>
      <VLazyLoadMultiSelect
        id="report-compare-question-option-flow"
        data-testid="input-report-compare-question-option-flow"
        label={t('flows')}
        useLazyApiQueryFunction={useLazyFetchFlowsWithPaginationQuery}
        apiRequestParameters={{ nodeId: accountId, sortBy: 'name', sortOrder: 'asc' }}
        multiSelectItemMapper={(items) => {
          return items.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }}
        searchKey="flow_name"
        value={formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds}
        searchable={true}
        clearable={true}
        onBlur={formik.handleBlur}
        onChange={handleOnChangeFlow}
        error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionOptionFlowIds')}
        withAsterisk={required}
        itemComponent={VSelectOptionWithDescription}
      />
      <If value={isFlowSelected}>
        <VLazyLoadMultiSelect
          id="report-compare-question-option-question"
          data-testid="input-report-compare-question-option-question"
          label={t('question')}
          useLazyApiQueryFunction={useLazyFetchFlowQuestionsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            flowIds: formik.values.selectedCompareCriteria.selectedQuestionOptionFlowIds,
            style: QUESTION_WITH_OPTION_TYPES,
            sortBy: 'name',
            sortOrder: 'asc',
          }}
          multiSelectItemMapper={(items) => {
            // body si null olanları filtrele
            return items
              .filter((item) => item.body !== null)
              .map((item) => ({
                value: item.id,
                label: textWithNativeEmoji(getBodyText(item.body, i18n.language)),
                description: item?.flow_name,
              }));
          }}
          searchKey="question_body"
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionOptionQuestionIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionOptionQuestionIds')}
          resetChangeRequestParameters={true}
          withAsterisk={required}
          onDefaultValuesLoaded={handleDefaultQuestionValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
      <If value={isFlowQuestionSelected}>
        <VLazyLoadMultiSelect
          id="report-compare-question-option-question-option"
          data-testid="input-report-compare-question-option-question-option"
          label={t('questionOption')}
          useLazyApiQueryFunction={useLazyFetchFlowQuestionOptionsWithPaginationQuery}
          apiRequestParameters={{
            nodeId: accountId,
            questionIds: formik.values.selectedCompareCriteria.selectedQuestionOptionQuestionIds,
            sortBy: 'name',
            sortOrder: 'asc',
          }}
          multiSelectItemMapper={(items) => items.map(mapItemToSelectOption)}
          searchKey="option_body"
          searchable={true}
          value={formik.values.selectedCompareCriteria.selectedQuestionOptionIds}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('selectedCompareCriteria.selectedQuestionOptionIds', e)}
          error={translateErrorMessage(formik, 'selectedCompareCriteria.selectedQuestionOptionIds')}
          withAsterisk={required}
          resetChangeRequestParameters={true}
          onDefaultValuesLoaded={handleDefaultQuestionOptionValuesLoaded}
          itemComponent={VSelectOptionWithDescription}
        />
      </If>
    </ReportsDetailGraphicCompareLayout>
  );
}
