import React from 'react';

interface CustomIconCatiProps extends React.SVGProps<SVGSVGElement> {}

export function CustomIconCati({ height, width, className, ...props }: CustomIconCatiProps) {
  return (
    <svg
      className={className}
      fill="none"
      width={width || 19}
      height={height || 18}
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_325_40)">
        <path
          d="M17.5811 11.2597L14.1299 9.78013C13.3775 9.45395 12.4908 9.67086 11.9791 10.3055L11.0147 11.4829C9.44094 10.5779 8.12267 9.25999 7.2191 7.7189L8.3982 6.75312C9.02764 6.23931 9.24484 5.35788 8.92607 4.60754L7.4384 1.111C7.08133 0.295643 6.19639 -0.149288 5.33429 0.049982L2.12734 0.790128C1.28809 0.982018 0.701172 1.719 0.701172 2.58215C0.701172 11.0836 7.61412 17.9966 16.1156 17.9966C16.9788 17.9966 17.7157 17.41 17.8764 16.5722L18.6165 13.3621C18.8499 12.4968 18.4 11.6112 17.5811 11.2597ZM17.0048 12.9818L16.2636 16.1944C16.2471 16.233 16.1895 16.3097 16.1153 16.3097C8.54514 16.3097 2.3878 10.1523 2.3878 2.58219C2.3878 2.5086 2.43338 2.45096 2.50532 2.43448L5.71543 1.69363C5.72642 1.69088 5.73795 1.68978 5.74893 1.68978C5.80987 1.68978 5.86698 1.72658 5.89116 1.78147L7.37216 5.23725C7.39851 5.29931 7.38094 5.37178 7.32823 5.41515L5.61439 6.78932C5.31127 7.03751 5.21902 7.46199 5.39144 7.81343C6.55156 10.1762 8.4933 12.1187 10.8564 13.2784C11.1762 13.4508 11.6328 13.3586 11.8812 13.0555L13.2874 11.338C13.3264 11.2885 13.3994 11.2698 13.4604 11.2984L16.9137 12.7777C16.9802 12.8377 17.0188 12.9115 17.0048 12.9818Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2374 2.73833C11.3307 2.33474 11.7334 2.08314 12.137 2.17637C12.8719 2.34613 13.9501 2.78392 14.8823 3.56505C15.827 4.35658 16.6538 5.5317 16.758 7.14695C16.7847 7.56031 16.4712 7.91702 16.0579 7.9437C15.6445 7.97037 15.2878 7.6569 15.2611 7.24355C15.1895 6.13452 14.6326 5.31271 13.919 4.71481C13.193 4.10651 12.3384 3.76238 11.7994 3.63788C11.3958 3.54466 11.1442 3.14191 11.2374 2.73833Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_325_40">
          <rect width="17.9941" height="17.9941" fill="white" transform="translate(0.701172 0.00292969)" />
        </clipPath>
      </defs>
    </svg>
  );
}
