import { ComboboxItem, ComboboxParsedItemGroup, isOptionsGroup } from '@mantine/core';
import { KeyValue, OptionsGroup, SortableList } from '@vision/ui/interfaces';

export function ensureArray<T>(array: T | T[]): T[] {
  return Array.isArray(array) ? array : [array].filter(Boolean);
}

// split array into chunks, useful for pagination
// Example: const arr = [1,2,3,4,5,6] => chunk(arr, 3) => [[1,2,3], [4,5,6]]
export const arrayChunk = <T>(arr: T[], size: number) =>
  Array.from(
    {
      length: Math.ceil(arr.length / size),
    },
    (v, i) => arr.slice(i * size, i * size + size),
  ) as T[][];

export function convertEnumToSelectItems<T>(
  values: T,
  mapper: (item: KeyValue) => ComboboxItem = (item) => ({
    label: item.value,
    value: item.value,
  }),
): ComboboxItem[] {
  return Object.entries(values).map(([key, value]) => mapper({ key, value }));
}

export function createSortableList<T>(list: T[]): SortableList<T> {
  return ensureArray(list).map((item, index) => ({ ...item, sortId: index + 1 }));
}

export function areArraysEqualInOrder<T>(arr1: T[], arr2: T[]) {
  if (arr1.length !== arr2.length) {
    return false; // Arrays have different lengths, so the order is different
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false; // Elements at the same index are different, so the order is different
    }
  }

  return true; // All elements are the same in the same order
}

export function uniqueArray<T>(arr: T[], objKey: keyof T, findDuplicate: boolean = false) {
  return (
    arr
      .map((e) => e[objKey])
      // store the keys of the unique objects
      .map((e, i, final) => (findDuplicate ? final.indexOf(e) !== i : final.indexOf(e) === i) && i)
      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e])
  );
}

export function sortByOrder<T extends { order: number }>(a: T, b: T) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }

  return 0;
}

export function arrayGroupBy<T>(list: T[], predicate: (value: T) => any) {
  const map: Record<string, T[]> = {};
  list.forEach((item: T) => {
    const key = predicate(item);
    if (!map[key]) {
      map[key] = [item];
    } else {
      map[key].push(item);
    }
  });
  return map;
}

export function convertArrayToSelectItems<T>(
  list: T[],
  predicate: (value: T, index?: number) => ComboboxItem,
): ComboboxItem[] {
  return ensureArray(list).map(predicate);
}

export function convertArrayToGroupSelectItems<T>(
  list: T[],
  predicate: (value: T, index?: number) => ComboboxParsedItemGroup,
): ComboboxParsedItemGroup[] {
  return ensureArray(list).map(predicate);
}

export function enumToArray<T>(enumObj: T): string[] {
  const keys = Object.keys(enumObj);
  const values = keys.map((key) => (enumObj as any)[key]);
  return values.filter((value) => typeof value === 'string');
}

// Helper function to return the value of an item of type ComboboxItem or Options Group
function getValueFromComboboxItemOrOptionsGroup(item: ComboboxItem | OptionsGroup): string {
  if (isOptionsGroup(item)) {
    return item.group;
  }
  return item.value;
}

export function filterUniqueItems(
  itemsToFilter: Array<ComboboxItem | OptionsGroup>,
  filterAgainst: Array<ComboboxItem | OptionsGroup>,
): Array<ComboboxItem | OptionsGroup> {
  // We obtain unique values by adding the values in newList to a Set.
  const listValues = new Set(filterAgainst.map((item) => getValueFromComboboxItemOrOptionsGroup(item)));

  // By filtering the newData array, we create a new array by filtering out values that are not in listValues
  const filteredData = itemsToFilter.filter((item) => !listValues.has(getValueFromComboboxItemOrOptionsGroup(item)));

  return filteredData;
}

export function updateArrayItemAtIndex<T>(array: T[], item: T, index: number) {
  return [...array.slice(0, index), item, ...array.slice(index + 1)];
}
