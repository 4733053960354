import { Checkbox, CheckboxProps, Group, Stack, Text, TextProps } from '@mantine/core';
import { ForwardRefWithStaticComponents } from '@vision/ui/interfaces';
import React, { forwardRef } from 'react';
import classes from './CheckboxCard.module.scss';

interface CheckboxCardProps extends Omit<CheckboxProps, 'label'> {}

const _CheckboxCard = forwardRef<HTMLInputElement, React.PropsWithChildren<CheckboxCardProps>>(
  ({ children, ...props }, ref) => (
    <Group className={classes.checkboxCard} wrap="nowrap" data-checked={props.checked}>
      <Checkbox
        ref={ref}
        labelPosition="left"
        classNames={{
          body: classes.checkboxCardBody,
          icon: classes.checkboxCardIcon,
          inner: classes.checkboxCardInner,
          input: classes.checkboxCardInput,
          label: classes.checkboxCardLabel,
          root: classes.checkboxCardRoot,
        }}
        label={<Stack>{children}</Stack>}
        {...props}
      />
    </Group>
  ),
) as any;

function CheckboxCardTitle({ children, ...props }: React.PropsWithChildren<TextProps>) {
  return (
    <Text className={classes.checkboxCardTitle} c="gray" fw={500} {...props}>
      {children}
    </Text>
  );
}

function CheckboxCardDescription({ children, ...props }: React.PropsWithChildren<TextProps>) {
  return (
    <Text className={classes.checkboxCardDescription} c="gray" size="xs" {...props}>
      {children}
    </Text>
  );
}

_CheckboxCard.Title = CheckboxCardTitle;
_CheckboxCard.Description = CheckboxCardDescription;

export const CheckboxCard: ForwardRefWithStaticComponents<
  HTMLInputElement,
  React.PropsWithChildren<CheckboxCardProps>,
  { Title: typeof CheckboxCardTitle; Description: typeof CheckboxCardDescription }
> = _CheckboxCard;
