import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ReportDateRangeType,
  ReportDetail,
  ReportDetailFilterOptions,
  ReportWidget,
  ReportWidgetLayoutRequest,
} from '@vision/ui/interfaces';
import { ReportDatePickerValue } from '@vision/ui/interfaces/report-date-picker-value';
import {
  DEFAULT_REPORT_DATE_FORMAT,
  DEFAULT_REPORT_DATE_PICKER_VALUE,
} from '@vision/ui/pages/ReportsDetailGraphic/constants';
import { ReportWidgetsService } from '@vision/ui/services';
import { insertIfObject } from '@vision/ui/utils';
import dayjs from 'dayjs';

const getVoiceDateFormat = (date: string | undefined, defaultValue: string): string => {
  if (date && dayjs(date, DEFAULT_REPORT_DATE_FORMAT, true).isValid()) {
    return dayjs(date).format(DEFAULT_REPORT_DATE_FORMAT);
  }

  return defaultValue;
};

const DEFAULT_REPORT_FILTER_OPTIONS: ReportDetailFilterOptions = {
  from_date: DEFAULT_REPORT_DATE_PICKER_VALUE.fromDate,
  to_date: DEFAULT_REPORT_DATE_PICKER_VALUE.toDate,
  channel_types: [],
  flow_id: [],
  nodes: [],
  tag_groups: [],
  tags: [],
};

function checkReportDetailFilters(detail?: ReportDetailFilterOptions): ReportDetailFilterOptions {
  if (!detail) {
    return DEFAULT_REPORT_FILTER_OPTIONS;
  }

  const filteredDetail: ReportDetailFilterOptions = {
    from_date: detail?.from_date ?? DEFAULT_REPORT_DATE_PICKER_VALUE.fromDate,
    to_date: detail?.to_date ?? DEFAULT_REPORT_DATE_PICKER_VALUE.toDate,
    channel_types: Array.isArray(detail?.channel_types) ? detail.channel_types : [],
    flow_id: Array.isArray(detail?.flow_id) ? detail.flow_id : [],
    nodes: Array.isArray(detail?.nodes) ? detail.nodes : [], // aslında channel.
    tag_groups: Array.isArray(detail?.tag_groups) ? detail.tag_groups : [],
    tags: Array.isArray(detail?.tags) ? detail.tags : [],
  };

  return filteredDetail;
}

interface ReportWidgetsStateType {
  reportDetail?: ReportDetail;
  widgets?: ReportWidget[];
  selectedDate: ReportDatePickerValue;
  changedToDateRange: number;
  selectedMainFilters: ReportDetailMainFilterType;
  activeResizingWidgetId?: string;
}

type ReportDetailMainFilterType = {
  selectedChannelIds: Array<string>;
  selectedFlowIds: Array<string>;
  selectedUnitIds?: Array<string>;
  selectedTagIds?: Array<string>;
};

const initialState: ReportWidgetsStateType = {
  reportDetail: null,
  widgets: [],
  selectedDate: DEFAULT_REPORT_DATE_PICKER_VALUE,
  changedToDateRange: 0,
  selectedMainFilters: {
    selectedChannelIds: [],
    selectedFlowIds: [],
    selectedUnitIds: [],
    selectedTagIds: [],
  },
  activeResizingWidgetId: undefined,
};

export const ReportWidgetsState = createSlice({
  name: 'ReportWidgetsState',
  initialState,
  reducers: {
    updateReportDetailTitleAction: (state, { payload }: PayloadAction<string>) => {
      if (state.reportDetail) {
        state.reportDetail.title = payload;
      }
    },

    updateDateRangeAction: (state, action) => {
      state.selectedDate = action.payload;
      if (state.reportDetail && state.reportDetail.detail) {
        state.reportDetail.detail.from_date = action.payload.fromDate;
        state.reportDetail.detail.to_date = action.payload.toDate;
      }
      state.changedToDateRange = state.changedToDateRange + 1;
    },

    updateReportMainFiltersAction: (state, { payload }: PayloadAction<ReportDetailMainFilterType>) => {
      if (!state.reportDetail?.detail) {
        return;
      }

      const { selectedChannelIds, selectedUnitIds, selectedFlowIds, selectedTagIds } = payload;

      // Update main filters state
      state.selectedMainFilters = {
        selectedChannelIds: selectedChannelIds ?? [],
        selectedUnitIds: selectedUnitIds ?? [],
        selectedFlowIds: selectedFlowIds ?? [],
        selectedTagIds: selectedTagIds ?? [],
      };

      // Update report detail with corresponding values
      Object.assign(state.reportDetail.detail, {
        nodes: selectedChannelIds,
        unit_ids: selectedUnitIds,
        flow_id: selectedFlowIds,
        tags: selectedTagIds,
      });
    },

    updateReportWidgetsLayoutAction: (state, { payload }: PayloadAction<ReportWidgetLayoutRequest[]>) => {
      state.widgets = state.widgets.map((widget: ReportWidget) => {
        const layout = payload.find((layout: ReportWidgetLayoutRequest) => layout.id === widget.id);
        if (layout) {
          return {
            ...widget,
            layout: layout.layout,
          };
        }
        return widget;
      });
    },

    setActiveResizingWidgetIdAction: (state, { payload }: PayloadAction<string>) => {
      state.activeResizingWidgetId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ReportWidgetsService.endpoints.getReport.matchFulfilled, (state, { payload }) => {
      const { detail } = payload.data;

      const selectedDate: ReportDatePickerValue = {
        ...DEFAULT_REPORT_DATE_PICKER_VALUE,
        ...insertIfObject(!!detail?.from_date, {
          fromDate: getVoiceDateFormat(detail?.from_date, DEFAULT_REPORT_DATE_PICKER_VALUE.fromDate),
        }),
        ...insertIfObject(!!detail?.to_date, {
          toDate: getVoiceDateFormat(detail?.to_date, DEFAULT_REPORT_DATE_PICKER_VALUE.toDate),
        }),
        ...insertIfObject(!!detail?.date_range, { dateRange: detail?.date_range as ReportDateRangeType }),
      };

      // Update state in one assignment
      Object.assign(state, {
        reportDetail: {
          ...payload.data,
          latest_action_log: null, // not needed
          detail: checkReportDetailFilters(detail),
        },
        selectedDate,
        selectedMainFilters: {
          selectedFlowIds: detail?.flow_id || [],
          selectedChannelIds: detail?.nodes || [],
          selectedUnitIds: detail?.unit_ids || [],
          selectedTagIds: detail?.tags || [],
        },
      });
    });
    builder.addMatcher(ReportWidgetsService.endpoints.getReportWidgets.matchFulfilled, (state, { payload }) => {
      state.widgets = payload.data.map((widget) => ({
        ...widget,
        detail: {
          ...widget.detail,
          from_date: getVoiceDateFormat(
            widget.detail?.from_date ? widget.detail.from_date : state.selectedDate.fromDate,
            DEFAULT_REPORT_DATE_PICKER_VALUE.fromDate,
          ),
          to_date: getVoiceDateFormat(
            widget.detail?.to_date ? widget.detail.to_date : state.selectedDate.toDate,
            DEFAULT_REPORT_DATE_PICKER_VALUE.toDate,
          ),
        },
      }));
    });
    builder.addMatcher(ReportWidgetsService.endpoints.deleteReportWidget.matchFulfilled, (state, { payload }) => {
      state.widgets = state.widgets.filter((widget: ReportWidget) => widget.id !== payload.data.id); // Specify the type of widget
    });
    builder.addMatcher(ReportWidgetsService.endpoints.updateReportWidget.matchFulfilled, (state, { payload }) => {
      state.widgets = state.widgets.map((widget: ReportWidget) => {
        if (widget.id === payload.data.id) {
          return {
            ...widget,
            ...payload.data,
          };
        }
        return widget;
      });
    });
    builder.addMatcher(ReportWidgetsService.endpoints.copyReportWidget.matchFulfilled, (state, { payload, meta }) => {
      const copiedWidgetId = meta.arg.originalArgs?.widgetId;
      const copiedWidgetIndex = state.widgets.findIndex((widget: ReportWidget) => widget.id === copiedWidgetId);
      state.widgets.splice(copiedWidgetIndex + 1, 0, payload.data);
    });
  },
});

export const {
  updateReportDetailTitleAction,
  updateDateRangeAction,
  updateReportMainFiltersAction,
  updateReportWidgetsLayoutAction,
  setActiveResizingWidgetIdAction,
} = ReportWidgetsState.actions;
