import { AccountType, Role } from '@vision/ui/enums';
import i18n from '@vision/ui/i18n';
import { NavigationLink, NavigationLinkSimple } from '@vision/ui/interfaces';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import type routesNS from '../../../../public/locales/en/routes.json';

type RoutesI18nKey = keyof typeof routesNS;

export type RouteKeys =
  | 'Root'
  | 'Login'
  | 'LoginSso'
  | 'LoginLdap'
  | 'LoginLdapComplete'
  | 'ActivateUser'
  | 'ForgotPassword'
  | 'ResetPassword'
  | 'Unsubscribe'
  | 'ChatbotPreview'
  | 'WidgetPreview'
  | 'Dashboard'
  | 'Profile'
  | 'Settings'
  | 'Users'
  | 'UsersDetail'
  | 'TagGroups'
  | 'FlowTemplates'
  | 'Workflows'
  | 'WorkflowsRunHistory'
  | 'WorkflowsNew'
  | 'WorkflowsEdit'
  | 'ContactsDetail'
  | 'Business'
  | 'BusinessDetail'
  | 'Segments'
  | 'SegmentsDetail'
  | 'ContactsList'
  | 'Contacts'
  | 'Reports'
  | 'ReportsDetail'
  | 'ReportsDetailGraphic'
  | 'Insights'
  | 'Cati'
  | 'CatiList'
  | 'Inbox'
  | 'Flows'
  | 'Channels'
  | 'AuditLogs'
  | 'ContactPolicy'
  | 'QuickResponses'
  | 'ReviewWidgets'
  | 'KnowledgeBases'
  | 'Escalations'
  | 'AutomatedJobs'
  | 'ScheduleJobs'
  | 'EmailAutomations'
  | 'Units'
  | 'TreeView'
  | 'JourneyMaps'
  | 'RolesAndPermissions'
  | 'SmsProvider'
  | 'MailProvider'
  | 'HrisProvider'
  | 'FeedbackProvider'
  | 'FtpAccount'
  | 'RemoteDatabaseAccount'
  | 'SamlConfiguration'
  | 'SmtpConfiguration'
  | 'IntelligentTags'
  | 'ContentCategories'
  | 'ReportTheme'
  | 'PisanoMembers'
  | 'ReportWizard'
  | 'Accounts'
  | 'AccountStatistics'
  | 'Verticals'
  | 'LdapConfiguration'
  | 'SchemaFields'
  | 'QRCodeGenerator'
  | 'EXDashboard'
  | 'EXReports'
  | 'NewEXDashboard'
  | 'NewReports'
  | 'EXEmployees'
  | 'Leo';

export interface RouteDefinition extends Omit<NavigationLinkSimple, 'label'> {
  clojureHref?: string;
  translationKey?: RoutesI18nKey;
}

export const VisionRoutes: Record<RouteKeys, RouteDefinition> = {
  Root: {
    href: '/',
  },
  Login: {
    href: '/login',
    translationKey: 'login',
  },
  LoginSso: {
    href: '/login/sso',
    translationKey: 'login',
  },
  LoginLdap: {
    href: '/:accountCode/login',
    translationKey: 'login',
  },
  LoginLdapComplete: {
    href: '/:accountCode/complete',
    translationKey: 'login',
  },
  ActivateUser: {
    href: '/activate-user',
    translationKey: 'login',
  },
  ForgotPassword: {
    href: '/forgot-password',
    translationKey: 'forgotPassword',
  },
  ResetPassword: {
    href: '/reset-password',
    translationKey: 'resetPassword',
  },
  Unsubscribe: {
    href: '/unsubscribe',
    translationKey: 'unsubscribe',
  },
  ChatbotPreview: {
    href: '/chatbot-preview',
    translationKey: 'widgetPreview',
  },
  WidgetPreview: {
    href: '/widget-preview',
    translationKey: 'widgetPreview',
  },
  Dashboard: {
    href: '/dashboard',
    translationKey: 'dashboard',
  },
  Profile: {
    href: '/dashboard/profile',
    translationKey: 'profile',
  },
  Settings: {
    href: '/dashboard/settings',
    permission: 'settings',
    translationKey: 'dashboard',
  },
  Users: {
    application: 'cljs',
    href: '/dashboard/settings/users',
    clojureHref: '/dashboard/users',
    permission: 'settings.users',
    translationKey: 'users',
  },
  UsersDetail: {
    href: '/dashboard/settings/users/:id',
    permission: 'settings.users',
    translationKey: 'userDetail',
  },
  TagGroups: {
    href: '/dashboard/settings/tag-groups',
    clojureHref: '/dashboard/tags',
    permission: 'settings.tagGroups',
    translationKey: 'tagGroups',
  },
  Workflows: {
    href: '/dashboard/workflows',
    permission: 'workflows',
    translationKey: 'workflows',
    redirectTo: '/dashboard/workflows',
  },
  WorkflowsRunHistory: {
    href: '/dashboard/workflows/run-history',
    permission: 'workflows.workflowRunHistory',
    translationKey: 'runHistory',
  },
  WorkflowsNew: {
    href: '/dashboard/workflows/new',
    permission: 'workflows.workflowCreate',
    translationKey: 'workflowEditor',
  },
  WorkflowsEdit: {
    href: '/dashboard/workflows/:id/edit',
    permission: 'workflows.workflowEdit',
    translationKey: 'workflowEditor',
  },
  ContactsDetail: {
    href: '/dashboard/contacts/:id',
    permission: 'contacts',
    translationKey: 'contacts',
  },
  Business: {
    href: '/dashboard/contacts/businesses',
    permission: 'contacts',
    translationKey: 'businesses',
  },
  BusinessDetail: {
    href: '/dashboard/contacts/businesses/:id',
    permission: 'contacts',
    translationKey: 'businesses',
  },
  Segments: {
    href: '/dashboard/contacts/segments',
    permission: 'contacts',
    translationKey: 'segmentationGroups',
  },
  SegmentsDetail: {
    href: '/dashboard/contacts/segments/:id',
    permission: 'contacts',
    translationKey: 'segmentationGroups',
  },
  ContactsList: {
    href: '/dashboard/contacts/lists',
    application: 'cljs',
    clojureHref: '/dashboard/customers/lists',
    permission: 'contacts',
    translationKey: 'contactsList',
  },
  Contacts: {
    href: '/dashboard/contacts',
    application: 'cljs',
    clojureHref: '/dashboard/customers',
    permission: 'contacts',
    translationKey: 'contacts',
    redirectTo: '/dashboard/contacts',
  },
  Reports: {
    href: '/dashboard/reports',
    application: 'cljs',
    clojureHref: '/dashboard/report/',
    permission: 'reports',
    translationKey: 'reports',
  },
  NewReports: {
    href: '/dashboard/reports',
    clojureHref: '/dashboard/report/',
    permission: 'reports',
    translationKey: 'reports',
    disabledLeo: true,
    spotlightLabel: 'newReports',
  },
  ReportsDetail: {
    href: '/dashboard/reports/:id',
    permission: 'reports',
    translationKey: 'reports',
    disabledLeo: true,
  },
  ReportsDetailGraphic: {
    href: '/dashboard/reports/:id/graphic/:graphicId',
    permission: 'reports',
    translationKey: 'reports',
    disabledLeo: true,
  },
  Insights: {
    application: 'cljs',
    href: '/dashboard/report/?insight=true',
    permission: 'reports',
    translationKey: 'insights',
    disabledLeo: true,
  },
  Inbox: {
    application: 'cljs',
    href: '/dashboard/feedback',
    permission: 'inbox',
    translationKey: 'inbox',
  },
  Cati: {
    application: 'cljs',
    href: '/dashboard/cati',
    permission: 'catiCall',
    translationKey: 'cati',
  },
  CatiList: {
    href: '/dashboard/list-cati',
    permission: 'catiList',
    translationKey: 'catiList',
  },
  Flows: {
    application: 'cljs',
    href: '/dashboard/flows?usage=in_use',
    permission: 'flow',
    translationKey: 'flows',
  },
  Channels: {
    application: 'cljs',
    href: '/dashboard/channels',
    permission: 'channels',
    translationKey: 'channels',
  },
  AuditLogs: {
    application: 'cljs',
    href: '/dashboard/audit-logs',
    permission: 'settings.auditLogs',
    translationKey: 'auditLogs',
  },
  ContactPolicy: {
    href: '/dashboard/settings/contact-policy',
    permission: 'settings.contactPolicy',
    translationKey: 'contactPolicy',
  },
  QuickResponses: {
    application: 'cljs',
    href: '/dashboard/quick_responses',
    permission: 'settings.quickResponses',
    translationKey: 'quickResponses',
  },
  ReviewWidgets: {
    application: 'cljs',
    href: '/dashboard/review_widgets',
    permission: 'settings.reviewWidgets',
    translationKey: 'reviewWidgets',
  },
  KnowledgeBases: {
    application: 'cljs',
    href: '/dashboard/knowledge_bases',
    permission: 'settings.knowledgeBases',
    translationKey: 'knowledgeBases',
  },
  Escalations: {
    application: 'cljs',
    href: '/dashboard/escalations',
    permission: 'settings.escalations',
    translationKey: 'escalations',
  },
  AutomatedJobs: {
    application: 'cljs',
    href: '/dashboard/triggers',
    permission: 'settings.automatedJobs',
    translationKey: 'automatedJobs',
  },
  ScheduleJobs: {
    application: 'cljs',
    href: '/dashboard/schedule-jobs',
    permission: 'settings.scheduleJobs',
    translationKey: 'scheduleJobs',
  },
  EmailAutomations: {
    application: 'cljs',
    href: '/dashboard/email-automation/list',
    permission: 'settings.emailAutomations',
    translationKey: 'emailAutomations',
  },
  Units: {
    application: 'cljs',
    href: '/dashboard/hierarchy',
    permission: 'settings.hierarchy',
    translationKey: 'units',
  },
  TreeView: {
    application: 'cljs',
    href: '/dashboard/nodetree',
    permission: 'settings.hierarchy',
    translationKey: 'treeView',
  },
  JourneyMaps: {
    application: 'cljs',
    href: '/dashboard/journey_maps',
    permission: 'settings.journeyMaps',
    translationKey: 'customerJourneyMaps',
  },
  RolesAndPermissions: {
    application: 'cljs',
    href: '/dashboard/permissions',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings',
    translationKey: 'rolesAndPermissions',
  },
  SmsProvider: {
    application: 'cljs',
    //href: '/dashboard/settings/sms-provider', vision için url, aktif edildiğinde kullanılacak
    href: '/dashboard/account_settings/sms_settings/list',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.smsProviders',
    translationKey: 'smsProvider',
  },
  MailProvider: {
    application: 'cljs',
    href: '/dashboard/account_settings/mail_settings/list',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.mailProviders',
    translationKey: 'mailProvider',
  },
  HrisProvider: {
    application: 'cljs',
    href: '/dashboard/account_settings/hris_settings/list',
    accountType: [AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.hrisProviders',
    translationKey: 'hrisProvider',
  },
  FeedbackProvider: {
    application: 'cljs',
    href: '/dashboard/account_settings/feedback_providers',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.feedbackProviders',
    translationKey: 'feedbackProvider',
  },
  FtpAccount: {
    application: 'cljs',
    href: '/dashboard/account_settings/ftp',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.ftpAccounts',
    translationKey: 'ftpAccount',
  },
  RemoteDatabaseAccount: {
    application: 'cljs',
    href: '/dashboard/account_settings/remote_db_accounts',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.remoteDbAccounts',
    translationKey: 'remoteDatabaseAccount',
  },
  SamlConfiguration: {
    application: 'cljs',
    href: '/dashboard/account_settings/saml_settings',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.samlSettings',
    translationKey: 'samlConfiguration',
  },
  SmtpConfiguration: {
    application: 'cljs',
    href: '/dashboard/account_settings/smtp_settings',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.smtpSettings',
    translationKey: 'smtpConfiguration',
  },
  IntelligentTags: {
    application: 'cljs',
    href: '/dashboard/intelligent_tags',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.intelligentTags',
    translationKey: 'intelligentTags',
  },
  ContentCategories: {
    application: 'cljs',
    href: '/dashboard/content_categories',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.accountSettings.contentCategories',
    translationKey: 'contentCategories',
  },
  ReportTheme: {
    application: 'cljs',
    href: '/dashboard/account_settings/report_theme',
    accountType: [AccountType.ACCOUNT, AccountType.ACCOUNT_EX],
    permission: 'settings.reportTheme',
    translationKey: 'reportTheme',
  },
  PisanoMembers: {
    application: 'cljs',
    href: '/dashboard/settings/pisano-members',
    clojureHref: '/dashboard/pisano_members',
    role: Role.UBER_ADMIN,
    translationKey: 'pisanoMembers',
  },
  ReportWizard: {
    href: '/dashboard/settings/report-wizard',
    clojureHref: '/dashboard/widgets',
    role: [Role.UBER_ADMIN, Role.CONFIGURATOR],
    translationKey: 'reportWizard',
    disabledLeo: true,
  },
  Accounts: {
    application: 'cljs',
    href: '/dashboard/accounts',
    role: Role.UBER_ADMIN,
    translationKey: 'account',
  },
  AccountStatistics: {
    application: 'cljs',
    href: '/dashboard/account-statistics',
    role: Role.UBER_ADMIN,
    translationKey: 'accountStatistics',
  },
  Verticals: {
    application: 'cljs',
    href: '/dashboard/verticals',
    role: Role.UBER_ADMIN,
    translationKey: 'verticals',
  },
  LdapConfiguration: {
    application: 'cljs',
    href: '/dashboard/ldap_details',
    role: Role.UBER_ADMIN,
    translationKey: 'ldapConfiguration',
  },
  SchemaFields: {
    application: 'cljs',
    href: '/dashboard/settings/schema-fields',
    clojureHref: '/dashboard/schema_fields/customer_schema',
    permission: 'settings.customerSchema',
    translationKey: 'schema',
  },
  FlowTemplates: {
    application: 'cljs',
    href: '/dashboard/settings/flow-templates',
    role: [Role.UBER_ADMIN, Role.CONFIGURATOR],
    translationKey: 'flowTemplates',
  },
  QRCodeGenerator: {
    href: '/qr-code-generator',
  },
  EXDashboard: {
    application: 'cljs',
    href: '/dashboard/report/?ex-dashboard=true',
    permission: 'exDashboard',
    translationKey: 'dashboard',
    disabledLeo: true,
  },
  NewEXDashboard: {
    href: '/dashboard/ex-dashboard',
    permission: 'exDashboard',
    translationKey: 'dashboard',
    disabledLeo: true,
  },
  EXReports: {
    application: 'cljs',
    href: '/dashboard/report/?ex-reports=true',
    permission: 'exReports',
    translationKey: 'reports',
    disabledLeo: true,
  },
  EXEmployees: {
    application: 'cljs',
    href: '/dashboard/employees',
    permission: 'employees',
    translationKey: 'organizationChart',
    disabledLeo: true,
  },
  Leo: {
    href: '/leo',
    translationKey: 'leo',
  },
};

const SearchableRoutes = Object.entries(VisionRoutes).map(([key, routeDefinition]) => ({
  key,
  routeDefinition,
}));

export function getRoute(key: RouteKeys): Partial<NavigationLink> {
  const { application, clojureHref, href, translationKey, spotlightLabel, ...rest } = SearchableRoutes.find(
    (route) => route.key === key,
  ).routeDefinition;
  return {
    ...rest,
    application,
    id: key,
    label: i18n.t(`routes:${translationKey}`),
    spotlightLabel: spotlightLabel ? i18n.t(`routes:${spotlightLabel}`) : undefined,
    href: application === 'cljs' ? (clojureHref ?? href) : href,
  };
}

export function routeWithParameters(route: string, parameters: Record<string, any> = {}): string {
  return generatePath(route, parameters);
}

export function routeWithQueryParameters(route: string, parameters: Record<string, any> = {}): string {
  const params = qs.stringify(parameters);
  return `${route}?${params}`;
}
