import { createApi } from '@reduxjs/toolkit/query/react';
import { HttpMethod } from '@vision/ui/enums';
import { Language } from '@vision/ui/interfaces';
import { createHttpExtraOptions } from '../utils';
import { axiosBaseQuery } from './base.service';

export const LanguagesService = createApi({
  reducerPath: 'LanguagesService',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    languages: builder.query<Language[], void>({
      query: () => ({
        url: '/v1/languages',
        method: HttpMethod.GET,
        extraOptions: createHttpExtraOptions({
          notification: {
            autoHandleError: false,
          },
        }),
      }),
    }),
  }),
});

export const { useLanguagesQuery } = LanguagesService;
