import { HoverCard, HoverCardProps } from '@mantine/core';
import { ForwardRefWithStaticComponents } from '@vision/ui/interfaces';
import React, { forwardRef } from 'react';
import classes from './TooltipCard.module.scss';
import { TooltipCardContainer } from './TooltipCardContainer';

interface TooltipCardProps extends HoverCardProps {
  width?: number;
  id?: string;
}

const _TooltipCard = forwardRef<HTMLDivElement, React.PropsWithChildren<TooltipCardProps>>(
  ({ children, width, position, disabled, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        <HoverCard
          withArrow={true}
          arrowRadius={3}
          arrowSize={10}
          arrowOffset={15}
          position={position}
          disabled={disabled}
          width={width}
        >
          {children}
        </HoverCard>
      </div>
    );
  },
) as any;

function TooltipCardTarget({ children }: React.PropsWithChildren) {
  return <HoverCard.Target>{children}</HoverCard.Target>;
}

function TooltipCardDropdown({ children }: React.PropsWithChildren) {
  return (
    <HoverCard.Dropdown className={classes.tooltipCardDropdown}>
      <TooltipCardContainer>{children}</TooltipCardContainer>
    </HoverCard.Dropdown>
  );
}

_TooltipCard.Target = TooltipCardTarget;
_TooltipCard.Dropdown = TooltipCardDropdown;

export const TooltipCard: ForwardRefWithStaticComponents<
  HTMLDivElement,
  TooltipCardProps,
  { Target: typeof TooltipCardTarget; Dropdown: typeof TooltipCardDropdown }
> = _TooltipCard;
