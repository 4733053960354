import { MantineSize, Radio, Stack } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { Filter, FilterOption, FilterSelections } from '@vision/ui/interfaces';
import { updateArrayItemAtIndex } from '@vision/ui/utils';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

function updateSelectedItems(filter: Filter, filterSelections: FilterSelections[]) {
  const found = filterSelections.find((item) => item.key === filter.key);
  if (!found) {
    return null;
  }

  const selectedItems = filter.options.filter((item) => found.value === item.value);
  if (selectedItems.length) {
    return selectedItems[0].value;
  }
  const defaultSelectedItems = filter.options.filter((item) => item.default_selected);
  if (defaultSelectedItems.length) {
    return defaultSelectedItems[0].value;
  }
  return null;
}

interface DataGridFilterRadioControlProps {
  clearable: boolean;
  filter: Filter;
  filterSelections: FilterSelections[];
  onChange?: (selections: FilterSelections[]) => void;
  searchText?: string;
  size?: MantineSize;
}

export function DataGridFilterRadioControl({
  clearable,
  filter,
  filterSelections,
  onChange,
  searchText,
  size = 'sm',
}: DataGridFilterRadioControlProps) {
  const [value, setValue] = useState<string>(() => updateSelectedItems(filter, filterSelections));
  const options = useMemo(
    () =>
      filter.options.filter((option) =>
        searchText ? option.label.toLowerCase().includes(searchText.toLowerCase()) : true,
      ),
    [filter, searchText],
  );

  const calculateChanges = (val: string) => {
    if (!val) {
      return filterSelections.filter((item) => item.key !== filter.key);
    }

    const index = filterSelections.findIndex((item) => item.key === filter.key);
    if (index === -1) {
      return [
        ...filterSelections,
        {
          key: filter.key,
          value: val,
        },
      ];
    }
    const selection = filterSelections[index];
    return updateArrayItemAtIndex(
      filterSelections,
      {
        ...selection,
        value: val,
      },
      index,
    );
  };

  const handleRadioToggle = (option: FilterOption) => {
    if (option.value === value && clearable) {
      setValue(null);
    }
  };

  useDidUpdate(() => {
    const changes = calculateChanges(value);
    onChange?.(changes);
  }, [value]);

  useDeepCompareEffect(() => {
    setValue(updateSelectedItems(filter, filterSelections));
  }, [filter, filterSelections]);

  return (
    <Radio.Group name={filter.key} value={value} onChange={setValue} data-testid="section-filter-radio-control">
      <Stack gap={10}>
        {options.map((item, index) => (
          <Radio
            key={index}
            value={item.value}
            label={item.label}
            onClick={() => handleRadioToggle(item)}
            data-testid={`input-filter-radio-${item.value}`}
            size={size}
          />
        ))}
      </Stack>
    </Radio.Group>
  );
}
