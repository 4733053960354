export function coerceBooleanProperty(value: any): boolean {
  return value != null && `${value}` !== 'false';
}

export function isTruthy(value: any): boolean {
  return value !== null && value !== undefined;
}

export function isTruthyAll(...values: any[]): boolean {
  return values.every(isTruthy);
}

export function isFalsy(value: any): boolean {
  return !isTruthy(value);
}
