import { GeoJSONSourceAnyInput, GeoJSONSourceInput } from '@vision/ui/interfaces';
import * as echarts from 'echarts/core';
import { useEffect } from 'react';
import { EC } from '../../ECharts.types';

export interface MapRegisterProps {
  geoJson: GeoJSONSourceInput | GeoJSONSourceAnyInput;
  mapName: string;
  onRegistered?: () => void;
}

export const MapRegister: EC<MapRegisterProps> = ({ mapName, geoJson, onRegistered }) => {
  useEffect(() => {
    if (!mapName || !geoJson?.features) return;

    try {
      const features = Array.isArray(geoJson.features) ? geoJson.features : [];

      const processedGeoJson = {
        type: 'FeatureCollection' as const,
        features: features.map((feature: any) => ({
          type: 'Feature' as const,
          properties: {
            name: feature.properties?.name || feature.id,
            ...feature.properties,
          },
          geometry: feature.geometry,
        })),
      };

      echarts.registerMap(mapName, processedGeoJson);
      onRegistered?.();
    } catch (error) {
      console.error('[MapRegister] Error registering map:', error);
    }
  }, [mapName, geoJson, onRegistered]);

  return null;
};

MapRegister.optionOf = () => ({});
