import { Checkbox, MantineSize, Stack } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { Filter, FilterSelections } from '@vision/ui/interfaces';
import { updateArrayItemAtIndex } from '@vision/ui/utils';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

function updateSelectedItems(filter: Filter, filterSelections: FilterSelections[]) {
  const found = filterSelections.find((item) => item.key === filter.key);
  if (!found) {
    return [];
  }

  const selectedItems = filter.options.filter((item) => (found.value as string[]).includes(item.value));
  if (selectedItems.length) {
    return selectedItems.map((item) => item.value);
  }

  const defaultSelectedItems = filter.options.filter((item) => item.default_selected);
  if (defaultSelectedItems.length) {
    return defaultSelectedItems.map((item) => item.value);
  }

  return [];
}

interface DataGridFilterCheckboxControlProps {
  filter: Filter;
  filterSelections: FilterSelections[];
  onChange?: (selections: FilterSelections[]) => void;
  searchText?: string;
  size?: MantineSize;
}

export function DataGridFilterCheckboxControl({
  filter,
  filterSelections,
  onChange,
  searchText,
  size = 'sm',
}: DataGridFilterCheckboxControlProps) {
  const [value, setValue] = useState<string[]>(updateSelectedItems(filter, filterSelections));
  const options = useMemo(
    () =>
      filter.options.filter((option) =>
        searchText ? option.label.toLowerCase().includes(searchText.toLowerCase()) : true,
      ),
    [filter, searchText],
  );

  const calculateChanges = (val: string[]) => {
    if (!val.length) {
      return filterSelections.filter((item) => item.key !== filter.key);
    }

    const index = filterSelections.findIndex((item) => item.key === filter.key);
    if (index === -1) {
      return [
        ...filterSelections,
        {
          key: filter.key,
          value: val,
        },
      ];
    }
    const selection = filterSelections[index];
    return updateArrayItemAtIndex(
      filterSelections,
      {
        ...selection,
        value: val,
      },
      index,
    );
  };

  useDidUpdate(() => {
    const changes = calculateChanges(value);
    onChange?.(changes);
  }, [value]);

  useDeepCompareEffect(() => {
    setValue(updateSelectedItems(filter, filterSelections));
  }, [filter, filterSelections]);

  return (
    <Checkbox.Group value={value} onChange={setValue} data-testid="section-filter-checkbox-control">
      <Stack gap={10}>
        {options.map((item) => (
          <Checkbox
            key={item.value}
            value={item.value}
            label={item.label}
            data-testid={`input-filter-checkbox-${item.value}`}
            size={size}
          />
        ))}
      </Stack>
    </Checkbox.Group>
  );
}
