import { MantineSize, TextInput } from '@mantine/core';
import { Filter, FilterSelections, FilterValidationText, Nullable } from '@vision/ui/interfaces';
import { translateErrorMessage, updateArrayItemAtIndex } from '@vision/ui/utils';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { ObjectSchema, object, string } from 'yup';
import classes from './DataGridFilterTextControl.module.scss';

function getSelectedText(filter: Filter, filterSelections: FilterSelections[]): string {
  const found = filterSelections.find((item) => item.key === filter.key);
  if (!found) {
    return '';
  }

  return found.value as string;
}

interface DataGridFilterTextControlProps {
  filter: Filter;
  filterSelections: FilterSelections[];
  onChange?: (selections: FilterSelections[]) => void;
  size?: MantineSize;
}

export function DataGridFilterTextControl({
  filter,
  filterSelections,
  onChange,
  size = 'sm',
}: DataGridFilterTextControlProps) {
  const { t } = useTranslation(['translation', 'validation', 'data-grid']);

  const inputType = useMemo(() => {
    if (filter.validation) {
      return (filter.validation as FilterValidationText).type;
    }
    return 'text';
  }, [filter]);

  const textControlSchema = useMemo<Nullable<ObjectSchema<any>>>(() => {
    if (!filter.validation) {
      return null;
    }

    const { max, min } = filter.validation as FilterValidationText;

    return object().shape({
      [filter.key]: string()
        .min(
          min,
          t('validation:rules.generic.min', {
            count: min,
          }),
        )
        .max(
          max,
          t('validation:rules.generic.max', {
            count: max,
          }),
        )
        .trim(),
    });
  }, []);

  const initialValues = useMemo(() => {
    return {
      [filter.key]: getSelectedText(filter, filterSelections),
    };
  }, [filter, filterSelections]);

  const formik = useFormik({
    initialValues,
    validationSchema: textControlSchema,
    onSubmit(values) {
      const changes = calculateChanges(textControlSchema ? textControlSchema.cast(values) : values);
      onChange(changes);
    },
    validateOnChange: true,
  });

  const calculateChanges = (values: Record<string, string>) => {
    const val = values[filter.key];

    if (!val) {
      return filterSelections.filter((item) => item.key !== filter.key);
    }

    const index = filterSelections.findIndex((item) => item.key === filter.key);

    if (index === -1) {
      return [
        ...filterSelections,
        {
          key: filter.key,
          value: val,
        },
      ];
    }
    const selection = filterSelections[index];
    return updateArrayItemAtIndex(
      filterSelections,
      {
        ...selection,
        value: val,
      },
      index,
    );
  };

  useDeepCompareEffect(() => {
    const val = getSelectedText(filter, filterSelections);
    formik.setValues({
      [filter.key]: val,
    });
  }, [filterSelections]);

  useEffect(() => {
    formik.submitForm();
  }, [formik.values[filter.key]]);

  return (
    <TextInput
      classNames={{
        input: classes.textInput,
      }}
      data-testid="input-data-grid-text-control"
      type={inputType}
      id={filter.key}
      name={filter.key}
      placeholder={t('data-grid:enterTextFilter')}
      value={formik.values[filter.key]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={translateErrorMessage(formik, filter.key)}
      size={size}
    />
  );
}
